export default {
  demo: false,
  brand: "School Room Help Chat - SRH", // Footer brand
  appName: "School Room Chat Help - SRH", // App Name
  showCredits: false, // Show credits in login page
  theme: "light",
  // ***** Port Config *****
 
 
  // url: "http://localhost:4000", 
  // url: "http://192.168.18.91:4000",
  // url: "https://chat.20thfloor.com:4000",  // For Development
  //url: "https://chat.schoolroomhelp.com:4000",  // For Development 
  url: "https://chat.schoolroomhelp.net:4001",   //For Production
  
  
  // ***** Local Development *****
  // srhchat: "https://schoolroomhelp.20thfloor.com/",
  // redirectstudent: "https://auth.20thfloor.com",
  // redirecttutor: "https://auth.20thfloor.com/pro",
  // base_url: "https://schoolroomhelp.20thfloor.com/api",
  // home_url: "https://schoolroomhelp.20thfloor.com",
  // login_url: "https://auth.20thfloor.com",
  // logout_url:
  //   "https://auth.20thfloor.com/authorize?logout=true&callback=chat&logout_role=",


  // ***** 20thFloor Development *****
  // srhchat: "https://schoolroomhelp.20thfloor.com/",
  // redirectstudent: "https://auth.20thfloor.com",
  // redirecttutor: "https://auth.20thfloor.com/pro",
  // base_url: "https://schoolroomhelp.20thfloor.com/api",
  // home_url: "https://schoolroomhelp.20thfloor.com",
  // login_url: "https://auth.20thfloor.com",
  // logout_url: "https://auth.20thfloor.com/authorize?logout=true&callback=chat&logout_role=",


  // ***** SRH Production *****
  srhchat: "https://schoolroomhelp.net/",
  redirectstudent: "https://auth.schoolroomhelp.net",
  redirecttutor: "https://auth.schoolroomhelp.net/pro",
  base_url: "https://schoolroomhelp.net/api",
    home_url: "https://schoolroomhelp..net",
    login_url: "https://auth.schoolroomhelp.net",
    logout_url:
      "https://auth.schoolroomhelp.net/authorize?logout=true&callback=chat&logout_role=",

  
};
 